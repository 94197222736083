import React from 'react';
import styled from 'styled-components';
import { Helmet } from 'react-helmet';

import Masthead from '../components/Masthead';
import CtaBar from '../components/CtaBar';
import ExpertFinder from '../components/ExpertFinder';
import SideImageInfoSection from '../components/SideImageInfoSection';
import IndustryTiles from '../components/IndustryTiles';

import RelatedInsights from '../components/RelatedInsights';
import { PageWrap } from '../styles/common';

export default ({ industries }) => {
	return (
		<PageWrap>
			<Helmet>
				<title>Industries | Spergel Corporate</title>
			</Helmet>
			<Masthead
				image="/img/SpergelCorporate_Industries_Masthead.jpg"
				title="Industries"
			/>
			<SideImageInfoSection
				image={`/img/SpergelCorporate_IndustriesExplanation.jpg`}
				imageAlt={`Open for businesss.`}
				isLeft={true}
				mainContent={`## With Over 30 Years of Small Business & Corporate Debt Help, Trust the Spergel Approach\n\nSpergel Licensed Insolvency Trustees have over 30 years of experience developing business solutions for a wide range of industries. You can count on our small business and corporate debt help solutions to assist companies across various industries, including manufacturing, services, start-ups, and technology. We make it our business to understand yours and provide customized and cost-effective solutions.
				`}
			/>
			<IndustryTiles industries={industries} bg={'#f2f2f2'} />
			<RelatedWrap>
				<div style={{ textAlign: 'center' }}>
					<h3>Industry Articles:</h3>
				</div>
				<RelatedInsights />
			</RelatedWrap>
			<ExpertFinder />
			<CtaBar>
				<h3>
					Let's start a conversation.
					<br />
					<a href="/contact">Contact us today.</a>
				</h3>
			</CtaBar>
		</PageWrap>
	);
};

const RelatedWrap = styled.div`
	background-color: white;
	padding: 4rem 0 6rem 0;

	h2 {
		font-weight: 700;
	}
	h3 {
		font-weight: 400;
		font-size: 1.75rem;
		margin-bottom: 4rem;
	}
	a {
		margin-left: 0;
		color: inherit;
	}
`;
