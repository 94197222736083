import React from 'react';
import Layout from '../components/Layout';
import IndustriesTemplate from '../templates/IndustriesTemplate';
import { graphql } from 'gatsby';

const IndustriesPage = ({ data }) => {
	//const teamMembers = data.allTeamJson.edges.map((edge) => edge.node);
	const industries = data.allIndustriesJson.edges.map((item) => ({
		name: item.node.display_name,
		image: item.node.tile_image,
		slug: item.node.fields.slug,
	}));
	return (
		<Layout>
			<IndustriesTemplate industries={industries} />
		</Layout>
	);
};

export default IndustriesPage;

export const query = graphql`
	query {
		allIndustriesJson(
			sort: { order: ASC, fields: [order] }
			filter: { engagements_only: { ne: true } }
		) {
			edges {
				node {
					display_name
					tile_image
					fields {
						slug
					}
				}
			}
		}
	}
`;
